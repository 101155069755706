import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { TransformerType } from '@parashift/shared/types';

export interface TransformerParams {
  data_type: 'int' | 'float' | 'string';
  identifier: string;
  label: string;
}

const TransformerStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  dev: $localize `:@@common.dev:Dev` as 'dev',
  public: $localize `:@@common.public:Public` as 'public',
};
type TransformerStatus = (typeof TransformerStatus)[keyof typeof TransformerStatus];
export { TransformerStatus };

export interface TransformerPlainModel {
  id: string;
  created_at: string;
  identifier: string;
  parameters: TransformerParams[];
  status: TransformerStatus;
  title: string;
  updated_at: string;
}

@Model({ type: TransformerType })
export class Transformer extends BaseApiModel<TransformerPlainModel> {

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  identifier: string;

  @Attribute()
  parameters: TransformerParams[];

  @Attribute()
  status: TransformerStatus;

  @Attribute()
  title: string;

  @Attribute({ readonly: true })
  updated_at: string;
}
